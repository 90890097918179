import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './Home.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FacebookProvider, Page } from 'react-facebook';
import LogoFacebook from 'react-ionicons/lib/LogoFacebook';
import ImgOurFamily from './images/our_family.jpg';
import ImgHadlee from './images/img_hadlee.jpg';
import ImgBrand from './images/img_brand.jpg';
import ImgPlus from './images/plus.png';
import ImgBHSS from './images/Logos/bhss.png';
import ImgAICA from './images/Logos/aica.jpg';
import ImgWFS from './images/Logos/watertown.png';

import ImgCattle1_1 from './images/2244/2244.jpg';
import ImgCattle2_1 from './images/914P/914P.jpg';
import ImgCattle3_1 from './images/8355/8355.jpg';
import ImgCattle4_1 from './images/9130/9130.jpg';
//import ImgCattle4_1 from './images/photo_coming_soon.png';
import ImgCattle5_1 from './images/9918/9918.jpg';
import ImgCattle6_1 from './images/882/882-1.jpg';
import ImgCattle6_2 from './images/882/882-2.jpg';
import ImgCattle7_1 from './images/222/222.jpg';
//import ImgCattle8_1 from './images/504P/504P.jpg';
//import ImgCattle9_1 from './images/photo_coming_soon.png';
//import ImgCattle9_1 from './images/photo_coming_soon.png';
//import ImgCattle10_1 from './images/photo_coming_soon.png';

// Cattle 1: WCR Sir Fa Mac 2244
const cattle1title = <><strong>WCR Sir Fa Mac 2244</strong> <i>(AICA Reg. #M234430)</i></>;
const cattle1desc = <><p className="red-text"><b>One of the Breed's most time tested sires<br /></b></p> <ul style={{ listStyleType: "square" }}>
    <li>12-Time AICA Yearling Trait Leader</li>
    <li>5-Time AICA Milk Trait Leader</li>
    <li>55th Roll of Excellence Sire</li>
    <li>More than 2000 progeny indicate proof is in the genetics</li>
    <li>He has been used in 219 herds with 893 daughters in production</li></ul> <br />
    <p className="black-text"><b>EPDs</b></p>
    <table className="table table-bordered table-sm"><tbody><tr className="table-success"><th>Birth Wt.</th><th>Wng. Wt.</th><th>Yrlg. Wt.</th><th>Milk</th><th>Total Mtnl.</th><th>TSI</th></tr>
        <tr className="table-info"><td>3.2</td><td>29</td><td>58</td><td>10</td><td>25</td><td>201.23</td></tr></tbody></table></>;
const cattle1carousel_1 = <div><img src={ImgCattle1_1} className="cattle-image" alt="2244"></img></div>;
const cattle1imgtag = <img src={ImgCattle1_1} alt="WCR Sir Fa Mac 2244" className="herd-sires-item"></img>;
const cattle1tagline = <div className="herd-sires-item-name red-text">WCR Sir Fa Mac 2244<br /> <small className="white-text"><i>One of the Breed's most time tested sires</i></small></div>;

// Cattle 2: VCR Sir Duke 914 P
const cattle2title = <><strong>VCR Sir Duke 914 P</strong> <i>(AICA Reg. #M297007)</i></>;
const cattle2desc = <><p className="red-text"><b>The Breed's first #1 multiple trait leader!<br /></b></p><ul style={{ listStyleType: "square" }}>
    <li>His dam is one of 2244's greatest producing daughters</li>
    <li>4-Time AICA Weaning Weight Trait Leader</li>
    <li>6-Time AICA Milk Trait Leader - 2001 & 2002 he was #3</li>
    <li>Roll of Excellence Sire</li>
    <li>3-Time AICA Yearling Weight Trait Leader</li>
    <li>8-Time Multiple Trait Leader</li>
    <li>Semen: $25 signing fee</li></ul> <br />
    <p className="black-text"><b>EPDs</b></p>
    <table className="table table-bordered table-sm"><tbody><tr className="table-success"><th>Birth Wt.</th><th>Wng. Wt.</th><th>Yrlg. Wt.</th><th>Milk</th><th>Total Mtnl.</th><th>TSI</th></tr>
        <tr className="table-info"><td>-0.1</td><td>40</td><td>64</td><td>19</td><td>39</td><td>208.55</td></tr></tbody></table></>;
const cattle2carousel_1 = <div><img src={ImgCattle2_1} className="cattle-image" alt="914P"></img></div>;
const cattle2imgtag = <img src={ImgCattle2_1} alt="VCR Sir Duke 914 P" className="herd-sires-item"></img>;
const cattle2tagline = <div className="herd-sires-item-name red-text">VCR Sir Duke 914 P<br /><small className="white-text"><i>The Breed's first #1 multiple trait leader!</i></small></div>;

// Cattle 3: HC Rhinestone 8355
const cattle3title = <><strong>HC Rhinestone 8355</strong> <i>(AICA Reg. #M763414)</i></>;
const cattle3desc = <><p className="red-text"><b>Progeny have curve bending performance<br /></b></p><ul style={{ listStyleType: "square" }}>
    <li>Sire: LT Easy Pro 1158 P</li>
    <li>Dam: HC Romeo 6073</li>
    <li>Semen: $15/straw; $25 signing fee</li></ul> <br />
    <p className="black-text"><b>EPDs</b></p>
    <table className="table table-bordered table-sm"><tbody><tr className="table-success"><th>Birth Wt.</th><th>Wng. Wt.</th><th>Yrlg. Wt.</th><th>Milk</th><th>Total Mtnl.</th><th>TSI</th></tr>
        <tr className="table-info"><td>2.5</td><td>29</td><td>52</td><td>19</td><td>33</td><td>200.38</td></tr></tbody></table></>;
const cattle3carousel_1 = <div><img src={ImgCattle3_1} className="cattle-image" alt="8355"></img></div>;
const cattle3imgtag = <img src={ImgCattle3_1} alt="HC Rhinestone 8355" className="herd-sires-item"></img>;
const cattle3tagline = <div className="herd-sires-item-name red-text">HC Rhinestone 8355<br /><small className="white-text"><i>Progeny have curve bending performance</i></small></div>;

// Cattle 4: VCR Ledger 9130 P
const cattle4title = <><strong>VCR Ledger 9130 P</strong> <i>(AICA Reg. #M928497)</i></>;
const cattle4desc = <><p className="red-text"><b>Powerful performance bull with a lot of eye appeal<br /></b></p><ul style={{ listStyleType: "square" }}>
    <li>Sire: VCR Ledger 0332 P</li>
    <li>Dam: VCR Miss Silverman 3110 P</li>
    <li>This is an exceptional son of Ledger 0332 and one of our best 508 daughters</li>
    <li>We sold this bull to Fitch Brothers in Louisiana, but are using him A.I. and expecting his first calves in the spring of 2021</li>
    <li>WWT Top 10%, YWT Top 5%, SC Top 10%, TSI Top 7%</li></ul><br />
    <p className="red-text"><b>EPDs</b></p>
    <table className="table table-bordered table-sm"><tbody>
        <tr className="table-success"><th>Birth Wt.</th><th>Wng. Wt.</th><th>Yrlg. Wt.</th><th>Milk</th><th>Total Mtnl.</th><th>TSI</th></tr>
        <tr className="table-info"><td>1.8</td><td>43</td><td>84</td><td>4</td><td>25</td><td>226.71</td></tr></tbody></table></>;
const cattle4carousel_1 = <div><img src={ImgCattle4_1} className="cattle-image" alt="9130 P"></img></div>;
const cattle4imgtag = <img src={ImgCattle4_1} alt="VCR Ledger 9130 P" className="herd-sires-item"></img>;
const cattle4tagline = <div className="herd-sires-item-name red-text">VCR Ledger 9130 P<br /><small className="white-text"><i>Powerful performance bull with a lot of eye appeal</i></small></div>;

// Cattle 5: VCR Sir Duke 9918
const cattle5title = <><strong>VCR Sir Duke 9918</strong> <i>(AICA Reg. #M508800)</i></>;
const cattle5desc = <><p className="red-text"><b>Our calving ease specialist<br /></b></p><ul style={{ listStyleType: "square" }}>
    <li>Sired by Duke 914 out of a top producing 2244 daughter</li>
    <li>Linebred to Mac 2244's dam, the great Century 920</li>
    <li>9918 is seeing heavy AI service in our breeding program</li>
    <li>Used specially on heifers for calving ease</li>
    <li>Semen: $15/straw; $25 signing fee</li></ul> <br />
    <p className="red-text"><b>Summary</b></p>
    <table className="table table-bordered table-sm"><tbody>
        <tr className="table-success"><th>Birth Wt.</th><th>Adj. Wng. Wt.</th><th>Adj. Yrlg. Wt.</th></tr>
        <tr className="table-info"><td>84 lbs</td><td>782 lbs</td><td>1,231 lbs</td></tr></tbody></table>
    <p className="red-text"><b>EPDs</b></p>
    <table className="table table-bordered table-sm"><tbody>
        <tr className="table-success"><th>Birth Wt.</th><th>Wng. Wt.</th><th>Yrlg. Wt.</th><th>Milk</th><th>Total Mtnl.</th><th>TSI</th></tr>
        <tr className="table-info"><td>-5.3</td><td>32</td><td>57</td><td>17</td><td>33</td><td>211.29</td></tr></tbody></table></>;
const cattle5carousel_1 = <div><img src={ImgCattle5_1} className="cattle-image" alt="9918"></img></div>;
const cattle5imgtag = <img src={ImgCattle5_1} alt="VCR Sir Duke 9918" className="herd-sires-item"></img>;
const cattle5tagline = <div className="herd-sires-item-name red-text">VCR Sir Duke 9918<br /><small className="white-text"><i>Our calving ease specialist</i></small></div>;

// Cattle 6: SCR Sir County 882
const cattle6title = <><strong>SCR Sir County 882</strong> <i>(AICA Reg. #M922244)</i></>;
const cattle6desc = <><p className="red-text"><b>A performance sire<br /></b></p><ul style={{ listStyleType: "square" }}>
    <li>Sire: WCR Sir Countyline 5187 P</li>
    <li>Dam: SCR Miss Elimina Power 248</li>
    <li>Top 3% weaning weight EPDs</li>
    <li>Top 2% yearling weight EPDs</li>
    <li>Top 8% maternal weaning weight</li>
    <li>Top 4% carcass weight</li>
    <li>Top 6% rib eye area</li>
    <li>Top 2% TSI</li></ul> <br />
    <p className="red-text"><b>EPDs</b></p>
    <table className="table table-bordered table-sm"><tbody>
        <tr className="table-success"><th>Birth Wt.</th><th>Wng. Wt.</th><th>Yrlg. Wt.</th><th>Milk</th><th>Total Mtnl.</th><th>TSI</th></tr>
        <tr className="table-info"><td>-1.9</td><td>44</td><td>88</td><td>9</td><td>30</td><td>234.07</td></tr></tbody></table></>;
const cattle6carousel_1 = <div><img src={ImgCattle6_1} className="cattle-image" alt="882-1"></img></div>;
const cattle6carousel_2 = <div><img src={ImgCattle6_2} className="cattle-image" alt="882-2"></img></div>;
const cattle6imgtag = <img src={ImgCattle6_1} alt="SCR Sir County 882" className="herd-sires-item"></img>;
const cattle6tagline = <div className="herd-sires-item-name red-text">SCR Sir County 882<br /><small className="white-text"><i>A performance sire</i></small></div>;

// Cattle 7: SCR CE Corona Power 222
const cattle7title = <><strong>SCR CE Corona Power 222</strong> <i>(AICA Reg. #M826696)</i></>;
const cattle7desc = <><p className="red-text"><b>Outcross calving ease bull<br /></b></p><ul style={{ listStyleType: "square" }}>
    <li>Sire: JES Corona J370</li>
    <li>Dam: Miss Wind Power 047</li>
    <li>Lots of performance</li>
    <li>Semen: $15/straw; $25 signing fee</li></ul> <br />
    <p className="red-text"><b>EPDs</b></p>
    <table className="table table-bordered table-sm"><tbody>
        <tr className="table-success"><th>Birth Wt.</th><th>Wng. Wt.</th><th>Yrlg. Wt.</th><th>Milk</th><th>Total Mtnl.</th><th>TSI</th></tr>
        <tr className="table-info"><td>-3.9</td><td>24</td><td>49</td><td>30</td><td>42</td><td>196.87</td></tr></tbody></table></>;
const cattle7carousel_1 = <div><img src={ImgCattle7_1} className="cattle-image" alt="222"></img></div>;
const cattle7imgtag = <img src={ImgCattle7_1} alt="SCR CE Corona Power 222" className="herd-sires-item"></img>;
const cattle7tagline = <div className="herd-sires-item-name red-text">SCR CE Corona Power 222<br /><small className="white-text"><i>Outcross calving ease bull</i></small></div>;

// Cattle 4: VCR Area Code 605
//const cattle4title = <><strong>VCR Area Code 605</strong> <i>(AICA Reg. #M883394)</i></>;
//const cattle4desc = <><p className="red-text"><b>A performance herd sire that not only works for the commercial cow/calf producer but also the feedlot operator and the packer<br /></b></p><ul style={{ listStyleType: "square" }}>
//    <li>Sire: VCR Sir Sliverman 508 P</li>
//    <li>Dam: VCR Lady Ledger 485 P</li>
//    <li>Pedigree includes genetics of popular sires of today as well as sires of 20-30 years ago</li>
//    <li>Top 6% in yearling weight EPDs</li>
//    <li>Top 8% marbling EPD</li>
//    <li>Top 5% TSI</li></ul> <br />
//    <p className="red-text"><b>EPDs</b></p>
//    <table className="table table-bordered table-sm"><tbody><tr className="table-success"><th>Birth Wt.</th><th>Wng. Wt.</th><th>Yrlg. Wt.</th><th>Milk</th><th>Total Mtnl.</th><th>TSI</th></tr>
//        <tr className="table-info"><td>2.6</td><td>40</td><td>83</td><td>2</td><td>22</td><td>227.12</td></tr></tbody></table></>;
//const cattle4carousel_1 = <div></div>;
//const cattle4imgtag = <img src={ImgCattle4_1} alt="VCR Area Code 605" className="herd-sires-item"></img>;
//const cattle4tagline = <div className="herd-sires-item-name red-text">VCR Area Code 605<br /><small className="white-text"><i>A performance herd sire</i></small></div>;

// Cattle 9: VCR Sir Duke 352 P
//const cattle9title = <><strong>VCR Sir Duke 352 P</strong> <i>(AICA Reg. #M833098)</i></>;
//const cattle9desc = <><p className="red-text"><b>Our maternal powerhouse<br /></b></p><ul style={{ listStyleType: "square" }}>
//    <li>Sire: VCR Sir Duke 914 PLD</li>
//    <li>Dam: VCR Miss Wind 3144 P ET</li>
//    <li>One of our sr. herd sires that combines the genetics of Duke 914 and Wyoming Wind</li>
//    <li>His sons are widely accepted by our commercial customers and his daughters are making exceptional brood cows</li></ul><br />
//    <p className="red-text"><b>EPDs</b></p>
//    <table className="table table-bordered table-sm"><tbody>
//        <tr className="table-success"><th>Birth Wt.</th><th>Wng. Wt.</th><th>Yrlg. Wt.</th><th>Milk</th><th>Total Mtnl.</th><th>TSI</th></tr>
//        <tr className="table-info"><td>-0.4</td><td>22</td><td>41</td><td>21</td><td>32</td><td>190.86</td></tr></tbody></table></>;
//const cattle9carousel_1 = <div></div>;
//const cattle9imgtag = <img src={ImgCattle9_1} alt="VCR Sir Duke 352 P" className="herd-sires-item"></img>;
//const cattle9tagline = <div className="herd-sires-item-name red-text">VCR Sir Duke 352 P<br /><small className="white-text"><i>Our maternal powerhouse</i></small></div>;

// Cattle 10: IKE Stoneridge 9140
//const cattle10title = <><strong>IKE Stoneridge 9140</strong> <i>(AICA Reg. #M926673)</i></>;
//const cattle10desc = <><p className="red-text"><b>Our newest herd sire<br /></b></p><ul style={{ listStyleType: "square" }}>
//    <li>Sire: SCR Stoneridge 3171</li>
//    <li>Dam: UG MS High Standard 4030</li>
//    <li>This is a bull we purchased on the Sonderup sale in 2019</li>
//    <li>We like his phenotype and are excited to see his first calves this spring</li></ul><br />
//    <p className="red-text"><b>EPDs</b></p>
//    <table className="table table-bordered table-sm"><tbody>
//        <tr className="table-success"><th>Birth Wt.</th><th>Wng. Wt.</th><th>Yrlg. Wt.</th><th>Milk</th><th>Total Mtnl.</th></tr>
//        <tr className="table-info"><td>-0.2</td><td>36</td><td>75</td><td>10</td><td>29</td></tr></tbody></table></>;
//const cattle10carousel_1 = <div></div>;
//const cattle10imgtag = <img src={ImgCattle10_1} alt="IKE Stoneridge 9140" className="herd-sires-item"></img>;
//const cattle10tagline = <div className="herd-sires-item-name red-text">IKE Stoneridge 9140<br /><small className="white-text"><i>Our newest herd sire</i></small></div>;

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            title: '',
            content: '',
            carousel: ''
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    setModalTitle = (textTitle) => {
        this.setState({
            title: textTitle
        });
    }

    setModalContent = (textDesc) => {
        this.setState({
            content: textDesc
        });
    }

    setModalCarousel = (textCarousel) => {
        this.setState({
            carousel: textCarousel
        });
    }

    toggleAndSet = (index) => {
        this.toggle();
        switch (index) {
            case 1:
                {
                    this.setModalTitle(cattle1title);
                    this.setModalContent(cattle1desc);
                    this.setModalCarousel(cattle1carousel_1);
                    //example for future use: use an array! this.setModalCarousel([cattle1carousel_1, cattle1carousel_1, cattle1carousel_1]);
                    return;
                }
            case 2:
                {
                    this.setModalTitle(cattle2title);
                    this.setModalContent(cattle2desc);
                    this.setModalCarousel(cattle2carousel_1);
                    return;
                }
            case 3:
                {
                    this.setModalTitle(cattle3title);
                    this.setModalContent(cattle3desc);
                    this.setModalCarousel(cattle3carousel_1);
                    return;
                }
            case 4:
                {
                    this.setModalTitle(cattle4title);
                    this.setModalContent(cattle4desc);
                    this.setModalCarousel(cattle4carousel_1);
                    return;
                }
            case 5:
                {
                    this.setModalTitle(cattle5title);
                    this.setModalContent(cattle5desc);
                    this.setModalCarousel(cattle5carousel_1);
                    return;
                }
            case 6:
                {
                    this.setModalTitle(cattle6title);
                    this.setModalContent(cattle6desc);
                    this.setModalCarousel([cattle6carousel_1, cattle6carousel_2]);
                    return;
                }
            case 7:
                {
                    this.setModalTitle(cattle7title);
                    this.setModalContent(cattle7desc);
                    this.setModalCarousel(cattle7carousel_1);
                    return;
                }
            default:
                return;
        }
    }

    render() {
        var settings = {
            autoplay: true,
            autoplaySpeed: 2000,
            dots: true,
            fade: true,
            adaptiveHeight: true,
            slidesToShow: 1,
            pauseOnFocus: false,
            pauseOnHover: false,
            pauseOnDotsHover: false,
            useCSS: true,
            variableWidth: false
        };

        return (
            <div>

                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>{this.state.title}</ModalHeader>
                    <ModalBody>
                        {this.state.content}
                        <Slider {...settings}>{this.state.carousel}</Slider>
                        <div></div>
                    </ModalBody>
                </Modal>

                <React.Fragment>
                    <section className="intro" id="intro">
                        <div className="container">
                            <div className="intro-table">
                                <div className="intro-wrapper">
                                    <h1 className="intro-title">
                                        Vedvei Charolais Ranch
                                    </h1>
                                    <p className="intro-subtitle">
                                        since 1986
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </React.Fragment>

                <React.Fragment>
                    <section className="about-vcr white-background" id="about-vcr">
                        <div className="container">
                            <div className="section-wrapper less-bottom-pad">
                                <h2 className="section-title">
                                    <span className="about-vcr-span red-text underline-black">About VCR</span>
                                </h2>
                                <img src={ImgOurFamily} alt="Our Ranch" className="about-vcr-img"></img>
                                <div className="about-vcr-text">
                                    Vedvei Charolais Ranch (VCR) is a nationally recognized herd of purebred Charolais cattle located in Lake Preston, South Dakota. Al Vedvei, along with his wife Deb, started the operation in 1986 after nearly 10 years of working with his father-in-law at Wienk Charolais. Al and Deb now have 3 daughters (Corrie, Stacie, and Melanie) and 9 grandchildren (6 girls and 3 boys).<br /><br />
                                    After nearly 35 years, VCR has grown to over 300 registered Charolais cows, including both spring & fall calving cows. VCR bulls are sold private treaty and at 2 South Dakota state winter sales. Vedvei Charolais is a leader in the Charolais industry because of its commitment to the improvement of the breed's genetics. <br /><br />
                                    <b>Ranch Highlights:</b>
                                    <ul style={{ listStyleType: "square" }}>
                                        <li>2000 AICA Seed Stock Producer of the Year</li>
                                        <li>Two Roll of Excellence Herd Sires</li>
                                        <li>Home of WCR Sir Mac 2244</li>
                                        <li>Home of VCR Sir Duke 914</li>
                                        <li>Highest selling lot at 22nd National Charolais Sale in Denver</li>
                                        <li>Highest selling bull over all breeds at the 2020 Watertown Winter Farm Show</li>
                                    </ul>
                                    <br />
                                </div>
                                <h2 className="subsection-title">
                                    <span className="black-text underline-red big-screen">Legendary Foundation Sires</span>
                                    <span className="black-text underline-red small-screen">Legendary Sires</span>
                                </h2>
                                <div className="about-vcr-text">
                                    <p>VCR's two foundation herd sires, WCR Sir Fa Mac 2244 and VCR Sir Duke 914, have left a lasting legacy on the breed and will continue to in the future with the generation of offspring that still produce top males and females for today's seed stock industry.</p>
                                    <p>WCR Sir Fa Mac 2244, a bull produced while Al was working at Wienk Charolais, and VCR Sir Duke 914 were bulls that were many time trait leaders. Duke 914 was the Breed's first #1 multiple trait leader and held the position for several years. VCR continues to use these two herd sires today through artificial insemination on a limited basis.</p>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 col-xs-6">
                                        <div className="herd-sires-item">
                                            <div className="herd-sires-img">
                                                {cattle1imgtag}
                                                <div className="herd-sires-img-hover" onClick={() => this.toggleAndSet(1)} style={{ cursor: "pointer" }}>
                                                    <img src={ImgPlus} alt="plus" className="plus"></img>
                                                </div>
                                            </div>
                                            <div className="herd-sires-item-details">
                                                {cattle1tagline}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-xs-6">
                                        <div className="herd-sires-item">
                                            <div className="herd-sires-img">
                                                {cattle2imgtag}
                                                <div className="herd-sires-img-hover" onClick={() => this.toggleAndSet(2)} style={{ cursor: "pointer" }}>
                                                    <img src={ImgPlus} alt="plus" className="plus"></img>
                                                </div>
                                            </div>
                                            <div className="herd-sires-item-details">
                                                {cattle2tagline}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </React.Fragment>

                <React.Fragment>
                    <section className="herd-sires black-background less-bottom-pad" id="herd-sires">
                        <div className="container section-wrapper">
                            <h2 className="section-title">
                                <span className="herd-sires-span red-text underline-white">Herd Sires</span>
                            </h2>

                            <div className="row">
                                <div className="col-sm-6 col-xs-6">
                                    <div className="herd-sires-item">
                                        <div className="herd-sires-img">
                                            {cattle3imgtag}
                                            <div className="herd-sires-img-hover" onClick={() => this.toggleAndSet(3)} style={{ cursor: "pointer" }}>
                                                <img src={ImgPlus} alt="plus" className="plus"></img>
                                            </div>
                                        </div>
                                        <div className="herd-sires-item-details">
                                            {cattle3tagline}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xs-6">
                                    <div className="herd-sires-item">
                                        <div className="herd-sires-img">
                                            {cattle4imgtag}
                                            <div className="herd-sires-img-hover" onClick={() => this.toggleAndSet(4)} style={{ cursor: "pointer" }}>
                                                <img src={ImgPlus} alt="plus" className="plus"></img>
                                            </div>
                                        </div>
                                        <div className="herd-sires-item-details">
                                            {cattle4tagline}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xs-6">
                                    <div className="herd-sires-item">
                                        <div className="herd-sires-img">
                                            {cattle5imgtag}
                                            <div className="herd-sires-img-hover" onClick={() => this.toggleAndSet(5)} style={{ cursor: "pointer" }}>
                                                <img src={ImgPlus} alt="plus" className="plus"></img>
                                            </div>
                                        </div>
                                        <div className="herd-sires-item-details">
                                            {cattle5tagline}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xs-6">
                                    <div className="herd-sires-item">
                                        <div className="herd-sires-img">
                                            {cattle6imgtag}
                                            <div className="herd-sires-img-hover" onClick={() => this.toggleAndSet(6)} style={{ cursor: "pointer" }}>
                                                <img src={ImgPlus} alt="plus" className="plus"></img>
                                            </div>
                                        </div>
                                        <div className="herd-sires-item-details">
                                            {cattle6tagline}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xs-6">
                                    <div className="herd-sires-item">
                                        <div className="herd-sires-img">
                                            {cattle7imgtag}
                                            <div className="herd-sires-img-hover" onClick={() => this.toggleAndSet(7)} style={{ cursor: "pointer" }}>
                                                <img src={ImgPlus} alt="plus" className="plus"></img>
                                            </div>
                                        </div>
                                        <div className="herd-sires-item-details">
                                            {cattle7tagline}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </React.Fragment>

                <React.Fragment>
                    <section className="bulls-for-sale white-background" id="bulls-for-sale">
                        <div className="container section-wrapper">
                            <h2 className="section-title">
                                <span className="about-vcr-span red-text underline-black">Bulls for Sale</span>
                            </h2>

                            <p>We will be offering our bulls for sale by private treaty again this year. This is a unique and exciting set of bulls. We have called hard and only kept the best for your selection. We look forward to working with you as you select your next herd sire.</p><br />

                            <h3 className="subsection-title">
                                <span className="black-text underline-red">2024 Spring Bulls</span>
                            </h3>

                            <iframe title="2024 Spring Bulls" src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQ_8el6ufj9uuTo5UFh5Z4a6nXPvKAG3_Rdnd0eM-KNOD1v1bq2-R1zCA8QVo516dFfDrvF6tpNGhBn/pubhtml?gid=774230720&amp;single=true&amp;widget=true&amp;headers=false" className="iframe-bull-list"></iframe>
                            <a href="https://docs.google.com/spreadsheets/d/e/2PACX-1vQ_8el6ufj9uuTo5UFh5Z4a6nXPvKAG3_Rdnd0eM-KNOD1v1bq2-R1zCA8QVo516dFfDrvF6tpNGhBn/pub?output=xlsx" className="black-text">Download</a> &emsp;
                            <a href="https://docs.google.com/spreadsheets/d/e/2PACX-1vQ_8el6ufj9uuTo5UFh5Z4a6nXPvKAG3_Rdnd0eM-KNOD1v1bq2-R1zCA8QVo516dFfDrvF6tpNGhBn/pubhtml" target="_blank" className="black-text" rel="noopener noreferrer">Open</a>

                            <br /><br />

                            <p>If you are looking for an older bull, our 2023 Fall Bulls are exceptional. It is a small group but the quality is deep.</p><br />

                            <h3 className="subsection-title">
                                <span className="black-text underline-red">2023 Fall Bulls</span>
                            </h3>

                            <iframe title="2023 Fall Bulls" src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQ4pruLSGwMKw7gCkwEVCa3UPEFDP8_VUnea95a-yAvyTMtWTbg03PZxgvp6iBxvkR5bf4Z7s1JJmXs/pubhtml?gid=774230720&amp;single=true&amp;widget=true&amp;headers=false" className="iframe-bull-list"></iframe>
                            <a href="https://docs.google.com/spreadsheets/d/e/2PACX-1vQ4pruLSGwMKw7gCkwEVCa3UPEFDP8_VUnea95a-yAvyTMtWTbg03PZxgvp6iBxvkR5bf4Z7s1JJmXs/pub?output=xlsx" className="black-text">Download</a> &emsp;
                            <a href="https://docs.google.com/spreadsheets/d/e/2PACX-1vQ4pruLSGwMKw7gCkwEVCa3UPEFDP8_VUnea95a-yAvyTMtWTbg03PZxgvp6iBxvkR5bf4Z7s1JJmXs/pubhtml" target="_blank" className="black-text" rel="noopener noreferrer">Open</a>

                        </div>
                    </section>
                </React.Fragment>

                <React.Fragment>
                    <section className="fun black-background" id="links">
                        <div className="container section-wrapper text-center">
                            <h2 className="section-title">
                                <span className="about-vcr-span red-text underline-white">Links</span>
                            </h2>

                            <div className="row">
                                <div className="col-sm-4">
                                    <a href="http://charolaisusa.com/" target="_blank" rel="noopener noreferrer">
                                        <img src={ImgAICA} alt="American International Charolais Association" className="img-logo" />
                                    </a>
                                    <a href="http://charolaisusa.com/" target="_blank" rel="noopener noreferrer">
                                        <p className="white-text">American International Charolais Association</p>
                                    </a>
                                </div>
                                <div className="col-sm-4">
                                    <a href="http://blackhillsstockshow.com" target="_blank" rel="noopener noreferrer">
                                        <img src={ImgBHSS} alt="Black Hills Stock Show" className="img-logo" />
                                    </a>
                                    <a href="http://blackhillsstockshow.com" target="_blank" rel="noopener noreferrer">
                                        <p className="white-text">Black Hills Stock Show</p>
                                    </a>
                                </div>
                                <div className="col-sm-4">
                                    <a href="http://www.watertownwinterfarmshow.com/" target="_blank" rel="noopener noreferrer">
                                        <img src={ImgWFS} alt="Watertown Winter Farm Show" className="img-logo" />
                                    </a>
                                    <a href="http://www.watertownwinterfarmshow.com/" target="_blank" rel="noopener noreferrer">
                                        <p className="white-text">Watertown Winter Farm Show</p>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </section>
                </React.Fragment >

                <React.Fragment >
                    <section className="herd-sires white-background" id="contact-info">
                        <div className="container section-wrapper">
                            <h2 className="section-title">
                                <span className="about-vcr-span red-text underline-black">Contact Info</span>
                            </h2>

                            <div className="contact-group">

                                <div className="contact-text black-text">
                                    <p>
                                        Alan and Deb Vedvei <br />
                                        44213 204th Street <br />
                                        Lake Preston, SD 57249
                                    </p>
                                </div>

                                <div className="contact-text black-text">
                                    <p><a href="mailto:vedveicharolais@gmail.com" target="_blank" rel="noopener noreferrer">vedveicharolais@gmail.com</a><br />
                                        Home: 605-847-4529 <br />
                                        Al Cell: 605-860-1135</p>
                                </div>

                            </div>

                            <div className="contact-group">
                                <iframe title="Google Maps" width="500" height="350" frameBorder="0" style={{ border: "0" }} src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJDcEks9hAiIcRWTBBKvfHnXk&key=AIzaSyDic-8UjUtAGSRVe9gR0OvamGbLgf5rgyQ&zoom=6" allowFullScreen></iframe>
                            </div>
                        </div>
                    </section>
                </React.Fragment >

                <React.Fragment >
                    <section className="herd-sires black-background" id="media">
                        <div className="container section-wrapper">
                            <h2 className="section-title">
                                <span className="social-media-feed-span red-text underline-white">Media</span>
                            </h2>
                            <h3 className="subsection-title">
                                <span className="red-text underline-white">News Articles</span>
                            </h3>
                            <div className="news-articles">
                                <a className="white-text red-text-hover" href="https://rapidcityjournal.com/news/local/girls-aged-and-help-grandparents-prepare-bulls-for-auction/article_07335eb0-ac85-5d4e-9e3a-6ee4c6f82a37.html" target="_blank" rel="noopener noreferrer">Hadlee & Bentlee Featured in Rapid City Journal</a>
                            </div>
                            <br /><br />
                            <h3 className="subsection-title">
                                <span className="white-text underline-red">Photos</span>
                            </h3>
                            <div className="photos">
                                <img src={ImgHadlee} alt="Hadlee" className="media-img"></img>
                                <img src={ImgBrand} alt="Brand" className="media-img"></img>
                            </div>
                            <br /><br />
                            <h3 className="subsection-title">
                                <span className="white-text underline-red">Facebook Feed</span>
                            </h3>
                            <span className="big-screen">
                                <FacebookProvider appId="1202519279838646" className="bigScreen">
                                    <Page href="https://www.facebook.com/vedveicharolais" tabs="timeline, events, messages" width="500" />
                                </FacebookProvider>
                            </span>
                            <span className="small-screen">
                                <FacebookProvider appId="1202519279838646" className="smallScreen">
                                    <Page href="https://www.facebook.com/vedveicharolais" tabs="timeline, events, messages" />
                                </FacebookProvider>
                            </span>

                        </div>
                    </section>
                </React.Fragment >

                <React.Fragment >
                    <footer>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="copy">
                                        &copy;2025 VCR
                                    </div>
                                </div>
                                <div className="col text-center">

                                    <a href="https://www.facebook.com/vedveicharolais" target="_blank" rel="noopener noreferrer">
                                        <div className="icon">
                                            <LogoFacebook fontSize="20px" color="#3b5998" />
                                        </div>
                                    </a>
                                </div>
                                <div className="col text-right">
                                    <div className="copy">
                                        Theme by <a href="http://www.themewagon.com" target="_blank" rel="noopener noreferrer">themewagon.com</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </React.Fragment >
            </div>
        );
    }
}
